import {
  homeFilterSlotsIconHover,
  homeFilterFishingIconHover,
  homeFilterLiveCasinoIconHover,
  homeFilterCardGamesIconHover,
  homeFilterSportsIconHover,
} from '../../images';

export function getGameType(name) {
  if (name?.includes('sport')) {
    return 12;
  } if (name === 'live-casino') {
    return 8;
  } if (name?.includes('slot')) {
    return 0;
  } if (name === 'casino') {
    return 5;
  } if (name.includes('table')) {
    return 3;
  } if (name.includes('card')) {
    return 4;
  } if (name.indexOf('instant') >= 0) {
    return 1;
  }
  return '';
}

export function getGameName(type) {
  if (type === 12 || type === '12') {
    return 'game.sports';
  } if (type === 8 || type === '8') {
    return 'game.liveCasino';
  } if (type === 0 || type === '0') {
    return 'game.slots';
  } if (type === 5 || type === '5') {
    return 'game.casino';
  } if (type === 1 || type === '1') {
    return 'game.instantGames';
  } if (type === 3 || type === '3') {
    return 'game.tableGames';
  } if (type === 4 || type === '4') {
    return 'game.tableGames';
  }
  return '';
}

export function getTypeIcon(type) {
  if (type === 12 || type === '12') {
    return homeFilterSportsIconHover;
  } if (type === 8 || type === '8') {
    return homeFilterLiveCasinoIconHover;
  } if (type === 0 || type === '0') {
    return homeFilterSlotsIconHover;
  } if (type === 5 || type === '5') {
    return homeFilterCardGamesIconHover;
  } if (type === 1 || type === '1') {
    return homeFilterFishingIconHover;
  } if (type === 3 || type === '3') {
    return homeFilterCardGamesIconHover;
  } if (type === 4 || type === '4') {
    return homeFilterCardGamesIconHover;
  }
  return '';
}

export function getGameNameEn(type) {
  if (type === 12 || type === '12') {
    return 'Sports';
  } if (type === 8 || type === '8') {
    return 'Live Casino';
  } if (type === 0 || type === '0') {
    return 'slots';
  } if (type === 5 || type === '5') {
    return 'casino';
  } if (type === 1 || type === '1') {
    return 'Instants';
  } if (type === 3 || type === '3') {
    return 'Tables';
  } if (type === 4 || type === '4') {
    return 'Cards';
  }
  return '';
}
