import React from 'react';

const SkeletonBackground = ({id}) => {
    return(
        <div
            id={id}
            className='loading skeleton-gray h-28 w-full s:h-32 sm:h-36 md:h-44 lg:h-32 xlg:h-44'
        />
    )
}

export default SkeletonBackground