import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'next-i18next';
import CommonButton from '../common/CommonButton';

export default function App(props) {
  const { show, toggleShow, activeGame } = props;
  const { t } = useTranslation('common');
  const info = [
    { title: t('game.gameUsername'), value: activeGame?.loginId },
    {
      title: t('game.gamePassword'),
      value: activeGame?.password,
    },
  ];

  return (
    <div
      id='switch-popup-modal'
      tabIndex='-1'
      className={
        show
          ? 'overflow-scroll bg-black bg-opacity-60 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show'
          : 'overflow-scroll bg-black bg-opacity-60 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary'
      }
      role='dialog'
    >
      <div className='overlay-closeable' onClick={() => toggleShow(false)} />
      <div className='relative px-5 pt-4 md:pt-8 pb-10 rounded-lg z-50 modal-dialog'>
        <div className='flex justify-end'>
          <button
            type='button'
            data-modal-toggle='switch-popup-modal'
            onClick={() => toggleShow(false)}
          >
            <CloseOutlined style={{ fontSize: 20 }} />
          </button>
        </div>
        <div>
          <div className='text-white uppercase font-bold py-6 text-lg'>
            {activeGame?.name}
          </div>
          <div className='text-white text-sm font-normal mb-8'>
            {t('game.personalInfo')}
            <div className='border border-inputborder rounded-lg mt-2 text-sm'>
              {info.map((i, index) => {
                const first = index === 0;
                const last = index === 1;
                return (
                  <div
                    key={i.title}
                    className='flex border border-inputborder'
                    style={{
                      borderTopLeftRadius: first ? 7 : 0,
                      borderTopRightRadius: first ? 7 : 0,
                      borderBottomLeftRadius: last ? 7 : 0,
                      borderBottomRightRadius: last ? 7 : 0,
                    }}
                  >
                    <div className='flex-1 p-2'>{i.title}</div>
                    <div className='flex-1 border-l-2 p-2 pl-4'>
                      {i.value}
                      <CopyToClipboard text={i.value} onCopy={() => {}}>
                        <CopyOutlined
                          style={{ float: 'right', padding: 3 }}
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='grid grid-cols-12 gap-6'>
            <div className='col-span-6'>
              <CommonButton
                title={t('game.download')}
                onPress={() => {
                  window.location = activeGame?.games[0]?.appDownloadUrl;
                }}
              />
            </div>
            <div className='col-span-6'>
              <CommonButton
                title={t('game.play')}
                onPress={() => {
                  window.location = `${activeGame?.games[0]?.url}/?${
                    activeGame.name === 'pussy888' ? 'user' : 'account'
                  }=${activeGame?.loginId}&password=${
                    activeGame?.password
                  }`;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
