import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { getGameCred } from '../../api/getGameCred';
import { SettingActions } from '../reducers/settingReducer';
import ModalSwitchGame from '../dialog/switchGame';
import { gameDefaultImagepng, GameBadge } from '../../images';
import SkeletonBackground from '../common/SkeletonBackground';

export default function App(props) {
  const {
    games,
    selectedVendor,
    setSearchText,
  } = props;

  const dispatch = useDispatch();
  const router = useRouter();
  const userId = Cookies.get('userId');
  const [activeGame, setActiveGame] = useState(null);
  // const [isActive, setIsActive] = useState(null);
  const [showModalSwitch, setShowModalSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(30);
  const { t } = useTranslation('common');

  const saveToRecentPlayed = (x) => {
    const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

    const existingIndex = currentArray.findIndex((item) => item._id === x._id);

    let updatedArray;

    if (existingIndex !== -1) {
      updatedArray = [
        ...currentArray.slice(0, existingIndex),
        ...currentArray.slice(existingIndex + 1),
        x,
      ];
    } else {
      updatedArray = [...currentArray, x];
    }

    localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));
  };

  const gotoGame = async (k) => {
    if (Cookies.get('accessToken')) {
      dispatch(SettingActions.setLoading(true));
      const gameInfo = await getGameCred({
        brandId: selectedVendor || k.brandId,
        type: k.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo?.data?.data[0]?.brandInfo?.appType === '0') {
        saveToRecentPlayed(k);
        setShowModalSwitch(true);
        setActiveGame({
          gameCredId: gameInfo?.data?.data[0]?._id,
          ...gameInfo?.data?.data[0],
          ...k,
        });
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo) {
        saveToRecentPlayed(k);
        router.push(`/game_website?brandId=${selectedVendor || k.brandId}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${k._id}`);
        dispatch(SettingActions.setLoading(false));
      }
    } else {
      dispatch(SettingActions.setLoginShow(true));
    }
  };

  // const ifHasHotGames = games.filter((e) => topGames.includes(e.name));

  return (
    <div>
      {/* <p className='font-medium text-base mb-2 opacity-70'>{t('game.allGames')}</p> */}
      <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-5 gap-y-2 gap-2'>
        {(games || [])?.slice(0, limit)?.map((x, i) => (
          <div
            className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
            key={`${i}-${x._id}`}
            onClick={() => {
              const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

              const existingIndex = currentArray.findIndex((item) => item._id === x._id);

              let updatedArray;

              if (existingIndex !== -1) {
                updatedArray = [
                  ...currentArray.slice(0, existingIndex),
                  ...currentArray.slice(existingIndex + 1),
                  x,
                ];
              } else {
                updatedArray = [...currentArray, x];
              }

              localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

              gotoGame(x);
              setSearchText('');
            }}
          >
            <div className='absolute z-[2] leading-unset'>
              <div style={{ width: 40 }} className='leading-unset'>
                <Image src={GameBadge} alt='' />
              </div>
              <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                <img src={x.brandThumbnail} alt='' />
              </div>
            </div>
            <div className='relative game-overlay-img'>
              <div className='game-overlay'>
                <div className='triangle' />
                <p className='font-semibold text-base mt-1'>{t('game.play')}</p>
              </div>
              <SkeletonBackground id={`skeleton-id-${i}`} />
              <img
                className='hidden w-full'
                src={x.thumbnail || gameDefaultImagepng.src}
                onLoad={(e) => {
                  document.getElementById(`skeleton-id-${i}`)?.remove();
                  e.target.classList?.remove('hidden');
                }}
                alt='Cricket betting sites in ppx7'
              />
            </div>
          </div>
        ))}
      </div>
      {
        (limit < (games?.length || 0)) // in case games is undefined
      && (
        <div className='text-center mt-4'>
          <Button
            className='button-secondary py-3 lg:py-2.5 px-8'
            onClick={() => {
              const randomLoadTime = Math.floor(Math.random() * 2500) + 500;
              setLoading(true);
              setTimeout(() => {
                setLimit(limit + 30);
                setLoading(false);
              }, randomLoadTime);
            }}
          >
            <div className='flex items-center justify-center'>
              <span className='font-normal text-sm lg:text-xs uppercase'>
                {t('game.loadMore')}
                {' '}
                <span className='text-lg font-semibold' style={{ color: 'rgb(86,187,129)' }}>
                  {limit}
                </span>
                <span className='font-semibold text-sm lg:text-xs'>
                  /
                  {games.length}
                </span>
              </span>
              {
                loading
              && <span className='button-loader ml-2' />
              }
            </div>
          </Button>
        </div>
      )
      }

      <ModalSwitchGame
        activeGame={activeGame}
        show={showModalSwitch}
        toggleShow={setShowModalSwitch}
        setActiveGame={setActiveGame}
      />
    </div>
  );
}
